// Helper function for delays
const delay = ms => new Promise(resolve => window.setTimeout(resolve, ms));

// Add required elements
const appContainer = document.querySelector('.app');
const yearSpan = document.querySelector('.curyear');
const interBubble = document.querySelector('.interactive');
const aiMsg = document.querySelector('.ai-msg');

// Animation frame helpers
const requestAnimFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60);
  };

const cancelAnimFrame =
  window.cancelAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.mozCancelAnimationFrame ||
  function (id) {
    window.clearTimeout(id);
  };

// Update dynamic elements
function updateElementValues() {
  // Update curent year
  if (yearSpan) {
    yearSpan.textContent = new Date().getFullYear();
  }
}

// Initialize page animations
async function initializeAnimations() {
  try {
    if (!appContainer || !interBubble) return;

    // Interactive gradient animation
    let curX = 0;
    let curY = 0;
    let tgX = 0;
    let tgY = 0;
    let rafId = null;

    // Mouse follower (move interactive bubble)
    const initMouseFollower = () => {
      curX += (tgX - curX) / 20;
      curY += (tgY - curY) / 20;
      interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
      rafId = requestAnimFrame(initMouseFollower);
    };

    // Mouse move event listener
    const handleMouseMove = event => {
      tgX = event.clientX;
      tgY = event.clientY;
    };

    // Initial page load animation
    await delay(100);
    appContainer.classList.add('loaded');

    // AI message animation
    await delay(500);
    if (aiMsg) {
      aiMsg.classList.add('show');
    }

    // Mouse move event listener
    window.addEventListener('mousemove', event => {
      tgX = event.clientX;
      tgY = event.clientY;
    });

    // Initialize other functions
    updateElementValues();
    initMouseFollower();

    // Return cleanup function
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (rafId) {
        cancelAnimFrame(rafId);
      }
    };
  } catch (error) {
    console.error('Animation initialization error:', error);
  }
}

// Ensure animations are initialized after DOM is fully loaded
document.addEventListener('DOMContentLoaded', initializeAnimations);
